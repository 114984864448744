import React, { Component } from 'react';
import './App.css';
import Address2SVG from './Address2SVG';

class App extends Component {

	constructor(props){
		super(props);
		this.onResize = this.onResize.bind(this);
		this.state = { size: 320 };
	}

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false)
    this.onResize()
  }

  componentWillUnmount() {
    window.remoEventListener('resize')
    }

  onResize() {
    var size = window.innerHeight * 0.9;
    if (window.innerHeight > window.innerWidth) {
      size = window.innerWidth * 0.9;
    }
    this.setState({ size });
		console.log(size);
  }

  render() {
	  return (
    <div className="App">
      <Address2SVG size={ this.state.size } randomize="3000" />
    </div>
  	)
	}
}

export default (App);
